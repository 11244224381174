/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

        var hearder_menu = $("header .menu-mobile");
        if ($(window).width() < 768) {
          $("#hamburger-icon").on("click", function () {
            $(this).toggleClass('open');
            hearder_menu.fadeToggle();
            // $('.top-menu').parent().css('width', '100%');  
            // $('.top-menu').parent().css('margin', '0 auto');  
          });
        }

      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
        //SLICk
        $('#section5 .container-slider').slick({
          dots: true,
          fade: true,
          autoplay: true,
          autoplaySpeed: 5000,
          cssEase: 'linear',
          nextArrow: '<span id="next" class="arrow"></span>',
          prevArrow: '<span id="prev" class="arrow"></span>'
        });
        //SLICk Mobile
        $('#section5-mobile .container-mobile').slick({
         slidesToShow: 1.05,
          dots: true,
          focusOnSelect: true,
          autoplay: true,
          arrows: false
        });

        $(".btn-more").on("click", function () {
          $(".group-content").toggleClass("more");
          $('.arrow-down').toggleClass('active');
          document.getElementById("divcontent").style.transition = "all 2s";
        });

        $('#section8 .box-col-3').matchHeight();
      }
    }

  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
